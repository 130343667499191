import pdfIcon from "../assets/doc_icons/pdf.svg"
import imageIcon from "../assets/doc_icons/image.svg"
import docsIcon from "../assets/doc_icons/docx2.svg"
import excelIcon from "../assets/doc_icons/excel.svg"
import videoIcon from "../assets/doc_icons/video.svg"
import documentIcon from "../assets/doc_icons/document.svg"
import imageIcon2 from "../assets/doc_icons/image2.svg"
import filmIcon from "../assets/doc_icons/film.svg"

const delayHoursForView = 24

const handleDateValues = (date, placeholder = "No Date") => {
  return date && String(date)?.trim()?.length > 0 ? date : placeholder
}

const getFileIcon = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return pdfIcon; // Replace with actual path to your PDF icon image
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      return imageIcon; // Replace with actual path to your image icon
    case 'doc':
    case 'docx':
      return docsIcon; // Replace with actual path to your DOC/DOCX icon
    case 'xls':
    case 'xlsx':
    case 'csv':
      return excelIcon; // Replace with actual path to your Excel icon
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'mkv':
    case 'wmv':
      return videoIcon; // Replace with actual path to your video icon
    default:
      return docsIcon; // Replace with actual path to a default icon for unsupported types
  }
};

//   // Example usage
//   const fileName = 'example.pdf';
//   const iconPath = getFileIcon(fileName);
//   console.log(iconPath); // Outputs the path to the corresponding icon based on the file extension

const getFileCategory = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
    case 'doc':
    case 'docx':
    case 'xls':
    case 'xlsx':
    case 'csv':
    case 'ppt':
    case 'pptx':
    case 'txt':
      return documentIcon; // Category for document files

    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      return imageIcon2; // Category for image files

    case 'mp4':
    case 'mov':
    case 'avi':
    case 'mkv':
    case 'wmv':
    case 'flv':
      return filmIcon; // Category for video files

    default:
      return documentIcon; // Default case for unsupported or unknown file types
  }
};

//   // Example usage
//   const fileName = 'example.mp4';
//   const fileCategory = getFileCategory(fileName);
//   console.log(fileCategory); // Outputs 'video'

const containerRef = (ref) => {
  if (ref) {
    // https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
    ref._getBoundingClientRect = ref.getBoundingClientRect;

    ref.getBoundingClientRect = () => {
      const original = ref._getBoundingClientRect();

      return {
        ...original,
        width: Math.floor(original.width),
        height: Math.floor(original.height),
      };
    };
  }
}

const isRequired = (requiredObject, field) => {
  // console.log("requiredObject", requiredObject)
  return requiredObject[field]?.required
}

const getInterestOptions = (currency) => {
  return [
    { label: `+ ${currency}1,00,000`, value: "100000" },
    { label: `+ ${currency}2,00,000`, value: "200000" },
    { label: `+ ${currency}3,00,000`, value: "300000" },
    { label: `+ ${currency}5,00,000`, value: "500000" },
    { label: `+ ${currency}10,00,000`, value: "1000000" },
  ]
}

const intrestOptions = [
  { label: '+ ₹1,00,000', value: "100000" },
  { label: '+ ₹2,00,000', value: "200000" },
  { label: '+ ₹3,00,000', value: "300000" },
  { label: '+ ₹5,00,000', value: "500000" },
  { label: '+ ₹10,00,000', value: "1000000" },
]

const userTypeOptions = [
  { label: 'Investor', value: "investor" },
  { label: 'Startup', value: "startup" },
]

const colorArray = [
  "#D4F6FF",
  "#F6EFBD",
  "#FFE3E3",
  "#FFECC8",
  "#EDE8DC",
  "#F5EFFF",
  "#FEF9D9",
  "#F6EACB",
  "#FAF4B7",
  "#FFE6E6",
  "#FFF8DE",
  "#FFE8C5",
  "#F5EEE6",
  "#F2FFE9",
  "#F8E8EE",
  "#F8EDE3",
  "#F0EBE3",
  "#FEF2F4",
]

const technologyColor = "#FFF8DE"
const consumerBrandColor = "#FFE3E3"
const marketplaceColor = "#F2FFE9"
const servicesColor = "#F5EFFF"
const sectorAgnostic = "#F0EBE3"

const isEmptyObject = (object) => {
  let valuesArray = Object.values(object)
  for (const element of valuesArray) {
    if (element.trim() != "" && element != "null" && element != "undefined") {
      return false
    }
  }
  return true
}

const notEmpty = (value) => {
  return !(value === null || value === undefined || value === '' || (typeof value == "string" && value?.trim()?.length === 0) ||
    (Array.isArray(value) && value.length === 0) ||
    (!(value instanceof Date) && typeof value === 'object' && Object.keys(value).length === 0));
}

export {
  handleDateValues,
  getFileIcon,
  getFileCategory,
  containerRef,
  isRequired,
  intrestOptions,
  userTypeOptions,
  isEmptyObject,
  delayHoursForView,
  notEmpty,
  colorArray,
  technologyColor,
  consumerBrandColor,
  marketplaceColor,
  servicesColor,
  sectorAgnostic,
  getInterestOptions
}