import React, { useEffect, useRef, useState } from 'react'
import BackrrLogoLong from '../../assets/backrr_favicon.png'
import PersonLogo from '../../assets/founderImage.jpeg'
import LinkedInIconColor from '../../assets/linkedin_icon.png'
import { Add, Logout, RateReview, ThumbUp } from '@mui/icons-material'
import { useTheme } from '@emotion/react';
import AuthWrapper1 from '../pages/AuthWrapper1';
import { Box, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Popover, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { B300, BLUEBORDER, WHITEBG } from '../../ui-components/CustomButton';
import StartupLogo from '../../assets/business.png';
import BackrrLogoImage from '../../ui-components/BackrrLogoImage';
import seedImage from '../../assets/stage_icons/seed.png'
import preSeedImage from '../../assets/stage_icons/preSeed.png'
import earlyStageImage from '../../assets/stage_icons/seriesA.png'
import growthImage from '../../assets/stage_icons/seriesC.png'
import ipoImage from '../../assets/stage_icons/ipo.png'
import indiaFlag from '../../assets/india_flag.png'
import avatar from '../../assets/avatar.jpg'
import marketplaceIcon from '../../assets/marketplace.png'
import categoryIcon from '../../assets/category.png'
import stageIcon from '../../assets/stage.png'
import sectorIcon from '../../assets/sector.png'
import chequeSizeIcon from '../../assets/cheque_size.png'
import mobileShoppingIcon from '../../assets/mobile-shopping.png'
import digitalServiceIcon from '../../assets/digital-services.png'
import laptopScreenIcon from '../../assets/laptop-screen.png'
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { ReactComponent as RoundDetailsIcon } from "../../assets/rounds_icons/round_details_icon.svg"
import pranay from "../../assets/pranay.png"
import seedFullImage from '../../assets/stage_icons/seedFull.png'
import preSeedFullImage from '../../assets/stage_icons/preSeedFull.png'
import earlyStageFullImage from '../../assets/stage_icons/seriesAFull.png'
import ipoFullImage from '../../assets/stage_icons/ipoFull.png'
import CheckIcon from '@mui/icons-material/Check';


// import CustomPDFThumbnail from '../../ui-components/CustomPDFThumbnail';
import ThumbnailGenerator from '../../ui-components/CustomPDFThumbnail'
import gpsIcon from "../../assets/gps.svg"
import PerfectScrollbar from "react-perfect-scrollbar";
import { FetchProfileDetails, FetchProfileDetailsofStartup, FetchStartupDetails, FetchStatus, SaveFeedback, SaveInterest, SavePersonalInfo, SaveToList, SaveUserType } from '../../api';
import { Button } from '@mui/material'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie';
import { SET_MENU } from '../../store/actions'
import { useMediaQuery } from 'rsuite/esm/useMediaQuery/useMediaQuery'
import { toast } from 'react-hot-toast'
import CustomToast from '../../ui-components/CustomToast'
import ModalComponent from '../../ui-components/ModalComponent'
import NameInvestorPopup from './NameInvestorPopup'
import FeedbackPopup from './FeedbackPopup'
import SaveInterestPopup from './SaveInterestPopup'
import UserTypePopup from './UserTypePopup'
import { colorArray, consumerBrandColor, isEmptyObject, marketplaceColor, sectorAgnostic, servicesColor, technologyColor } from '../../utils'
import { Helmet } from 'react-helmet';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AlertDialog from '../../ui-components/popups/AlertPopup'
import MainWrappper from '../pages/MainWrapper'
import RoundDetail from './RoundDetail'
import AuthWrapperInvestor from '../pagesInvestor/AuthWrapperInvestor'
import MainWrappperInvestor from '../pagesInvestor/MainWrapperInvestor'
import CustomGallery from '../../ui-components/CustomGallery'
import LoginPopupforInvestor from './LoginInvestorPopup'

const CardHeader = ({ title, action = null, sx = {} }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography variant='h1' fontWeight={700} fontSize={"1rem"} color={'white'} lineHeight={"1rem"} sx={{ ...sx }}>
            {title}
        </Typography>
        {action && <BLUEBORDER
            component="label"
            role={undefined}
            tabIndex={-1}
            sx={{
                minWidth: "0 !important",
                display: "flex",
                gap: '0.5rem',
                backgroundColor: '#FFFAF5',
                border: "0"
            }}
            onClick={action}
        >
            <OpenInNewIcon sx={{ fontSize: 20 }} />
        </BLUEBORDER>}
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "", textAlign = "start" }) => {
    const [open, setOpen] = useState(false);

    let content = open ? htmlContent : htmlContent?.substring(0, 300);
    return (
        <>
            {content && content?.length > 0 ?
                <div className='row rich-text'>
                    <div dangerouslySetInnerHTML={{ __html: content }} className='row rich-text' style={{ color: color, textAlign: textAlign }} />
                    {
                        htmlContent?.length > 300 && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, color: 'white', justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => setOpen(!open)}>{!open ? '...more' : 'See less'}</Typography>
                        // <BLUEBORDER
                        //     component="label"
                        //     role={undefined}
                        //     // tabIndex={-1}
                        //     sx={{
                        //         backgroundColor: 'transparent',
                        //         border: "0",
                        //         padding: "0",
                        //         color: "white",
                        //         textTransform: 'none',
                        //         minWidth: 0
                        //     }}
                        //     onClick={setShowMore}
                        // > <div>{showMore ? '...more' : 'See less'}</div>
                        // </BLUEBORDER>
                    }
                </div>
                : "-"
            }
        </>
    );
};

const PersonDetails = ({ theme, data, index, length }) => {

    const isSmallScreen = (useMediaQuery(theme.breakpoints.down('sm')))[0];
    return <Grid xs={12} sm={12} sx={{
        padding: '0rem', boxShadow: 'none', borderRadius: "8px",
        // border: `1px solid ${theme.palette.customColors.gray300}`,
        overflow: 'hidden', paddingTop: 1, display: 'flex', flexDirection: "row", gap: { xs: "1rem", sm: "1.5rem" },
    }}>
        <Box style={{ flex: 0.3, width: "30%", maxWidth: '5.5rem' }}>
            <img src={data?.partner_image} onError={(ev) => { ev.target.src = avatar }} style={{ width: "100%", aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", marginInline: 'auto', display: 'block', border: `1px solid ${theme.palette.customColors.g75}` }} />
        </Box>
        <Box display={"grid"} gap={{ xs: 0.7, sm: 1 }} sx={{ placeContent: 'flex-start', flex: 1 }}>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={1}>
                {data?.partner_name && <Typography textAlign={"start"} sx={{ fontWeight: 600, marginLeft: 0, fontSize: { xs: "0.9rem", sm: "1rem" }, color: 'white' }}>{data?.partner_name}, {data?.type}</Typography>}
                {data?.linkedin_url && <LinkedInIcon sx={{ fontSize: 20 }} style={{ display: 'inline-block', marginInlineEnd: 'auto', color: 'white', cursor: 'pointer' }} onClick={() => { window.open(data?.linkedin_url) }} />}
            </Grid>
            {data?.bio && <Typography textAlign={"start"} sx={{ marginRight: 'auto', marginLeft: 0, color: 'white' }}>{data?.bio}</Typography>}
        </Box>
    </Grid>
}

const MicroVC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const technologySubCategory = ["Energy Technology", "Information Technology", "Medical Technology", "Energy Marketplace"]
    const category = ["Technology", "Consumer Brand", "Marketplace", "Services"]
    const stages = ["Pre-Seed", "Seed", "Expansion", "Growth"]
    const portfolioStartups = [
        {
            startup_name: "Shumee",
            startup_url: "https://www.shumee.in/",
            startup_logo: "https://fluidvc.in/wp-content/uploads/2022/01/unnamed-3-1.png"
        },
        {
            startup_name: "eské",
            startup_url: "https://www.eske.in/",
            startup_logo: "https://fluidvc.in/wp-content/uploads/2022/01/unnamed-4-1.png"
        },
        {
            startup_name: "Bummer",
            startup_url: "https://www.bummer.in/",
            startup_logo: "https://fluidvc.in/wp-content/uploads/2022/12/bummer-logo.png"
        },
        {
            startup_name: "Koparo",
            startup_url: "https://koparoclean.com/",
            startup_logo: "https://fluidvc.in/wp-content/uploads/2023/03/Screenshot_2023-03-31_at_19.54.12-removebg-preview.png"
        },
        {
            startup_name: "Beautywise",
            startup_url: "http://www.beautywise.in/",
            startup_logo: "https://fluidvc.in/wp-content/uploads/2024/03/beautywise-logo-01-65eed86cb731e-1024x359.webp"
        },
    ]
    const categoryArray = ['Technology', 'Consumer Brand', 'Marketplace', 'Services'
        // {
        //     title: 'Technology',
        //     icon: laptopScreenIcon
        // },
        // {
        //     title: 'Consumer Brand',
        //     icon: mobileShoppingIcon
        // },
        // {
        //     title: 'Marketplace',
        //     icon: marketplaceIcon
        // },
        // {
        //     title: 'Services',
        //     icon: digitalServiceIcon
        // },
    ]
    const sectorArray = ['Sector Agnostic'
        // {
        //     title: 'Information Technology',
        //     icon: laptopScreenIcon,
        //     category: "Technology"
        // },
        // {
        //     title: 'Digital Technology',
        //     icon: laptopScreenIcon,
        //     category: "Technology"
        // },
        // {
        //     title: 'Cosmetics Industry',
        //     icon: mobileShoppingIcon,
        //     category: "Consumer Brand"
        // },
        // {
        //     title: 'Mobile Accessories',
        //     icon: mobileShoppingIcon,
        //     category: "Consumer Brand"
        // },
        // {
        //     title: 'Energy Marketplace',
        //     icon: marketplaceIcon,
        //     category: "Marketplace"
        // },
        // {
        //     title: 'Digital Marketplace',
        //     icon: marketplaceIcon,
        //     category: "Marketplace"
        // },
        // {
        //     title: 'Medical Services',
        //     icon: digitalServiceIcon,
        //     category: "Services"
        // }
    ]
    const businessModalArray = [
        {
            title: 'B2B (Business-to-Business)',
            icon: laptopScreenIcon
        },
        {
            title: 'B2C (Business-to-Consumer)',
            icon: laptopScreenIcon
        },
        {
            title: 'B2B2C (Business-to-Business-to-Consumer)',
            icon: mobileShoppingIcon
        },
        {
            title: 'D2C (Direct-to-Consumer)',
            icon: mobileShoppingIcon
        },
        {
            title: 'C2C (Consumer-to-Consumer)',
            icon: marketplaceIcon
        },
        {
            title: 'C2B (Consumer-to-Business)',
            icon: marketplaceIcon
        },
        {
            title: 'B2G (Business-to-Government)',
            icon: digitalServiceIcon
        },
        {
            title: 'P2P (Peer-to-Peer)',
            icon: digitalServiceIcon
        }
    ]
    const stagesArray = ['Pre-Seed', 'Seed', 'Early Stage', 'Growth', 'Expansion'
        // {
        //     title: 'Pre-Seed',
        //     icon: preSeedFullImage,
        //     disable: false
        // },
        // {
        //     title: 'Seed',
        //     icon: seedFullImage,
        //     disable: false
        // },
        // {
        //     title: 'Early Stage',
        //     icon: earlyStageFullImage,
        //     disable: true
        // },
        // {
        //     title: 'Growth',
        //     icon: growthImage,
        //     disable: false
        // },
        // {
        //     title: 'Expansion',
        //     icon: ipoFullImage,
        //     disable: false
        // },
    ]
    const imagesArray = [
        {
            original: "https://events.fluidvc.in/wp-content/uploads/2023/12/OSC-Announcement.png",
            thumbnail: "https://events.fluidvc.in/wp-content/uploads/2023/12/OSC-Announcement.png",
            originalHeight: "80%",
            thumbnailHeight: "50rem",
            thumbnailWidth: "40rem",
        },
        {
            original: "https://events.fluidvc.in/wp-content/uploads/2023/08/LOGO.png",
            thumbnail: "https://events.fluidvc.in/wp-content/uploads/2023/08/LOGO.png",
            originalHeight: "80%",
            thumbnailHeight: "50rem",
            thumbnailWidth: "40rem",
        },
    ]
    const partners = [
        {
            partner_name: "Amit Singal",
            linkedin_url: "https://www.linkedin.com/in/amitsingalca/",
            partner_image: "https://fluidvc.in/wp-content/uploads/2022/03/Screenshot-2022-03-03-at-22.00.13-e1646325097185.jpg",
            bio: 'Partner @Fluid Ventures & RealTime Angel Fund, Director @ASR & Greenzo',
            type: 'Partner'
        },
        {
            partner_name: "Dhianu Das",
            linkedin_url: "https://www.linkedin.com/in/dhianu/",
            partner_image: "https://fluidvc.in/wp-content/uploads/2022/01/Dhianu-Das.jpg",
            bio: 'Dhianu Das is a prominent business leader, serial entrepreneur and investor with a mission to empower entrepreneurs & start-ups with investment and capacity building to enable fast business development.',
            type: 'AGILITY'
        },
        {
            partner_name: "Manish Aggarwal",
            linkedin_url: "https://www.linkedin.com/in/manishaggarwal3",
            partner_image: "https://fluidvc.in/wp-content/uploads/2022/01/Manish-Aggarwal.jpg",
            bio: 'Partner at Fluid Ventures',
            type: 'Partner'
        },
        {
            partner_name: "Prashant Narang",
            linkedin_url: "https://www.linkedin.com/in/prashantnarang/",
            partner_image: "https://fluidvc.in/wp-content/uploads/2022/01/PrashantNarang_4.jpg",
            bio: 'Prashant Narang is a founder at Agility Ventures, a startup investing platform that educates and empowers first-time investors in startups. He is also a chartered accountant with over 23 years of experience in finance and an author of the book "Four Bucket Cash Flow".',
            type: 'Founder'
        }
    ]

    const investorData = {
        "name": "Fluid Ventures",
        "bio": "Direct to Consumer focused Venture Capital Fund",
        "profile_image": "https://fluidvc.in/wp-content/uploads/2022/01/fluidventures.png",
        "location": "Gurugram, Haryana, India",
        "linkedin_url": "https://in.linkedin.com/company/fluid-ventures",
        "overview": "<p>D2C focused VC fund investing in consumer products/brands at Seed, Pre-Series A and Series A round-up to INR 4 Crore.</p>",
        "categories": categoryArray,
        "stages": stagesArray,
        "sectors": sectorArray,
        "checque_size": "₹50L to ₹2CR INR",
        "portfolio": portfolioStartups,
        "team": partners,
        "gallery": imagesArray
    }

    const [startupDetail, setStartupDetail] = useState(null)
    const [overview, setOverview] = useState(null)
    const [userDetail, setUserDetail] = useState(null)
    const [coFoundersArray, setCoFoundersArray] = useState([])

    const [showInitialLogo, setShowInitialLogo] = useState(false)
    const [showMore, setShowMore] = useState(true)

    const [openNamePopup, setOpenNamePopup] = useState(false);
    const [openInterestPopup, setOpenInterestPopup] = useState(false);
    const [openFeedbackPopup, setOpenFeedbackPopup] = useState(false);
    const [openTypePopup, setOpenTypePopup] = useState(false);

    const [enableShare, setEnableShare] = useState(false)
    const [enableFeedback, setEnableFeedback] = useState(true)

    const [alertImage, setAlertImage] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertHeading, setAlertHeading] = useState(null)

    const [buttonClickType, setButtonClickType] = useState(null)
    const [openLoginPopup, setOpenLoginPopup] = useState(false);

    const [isTokenAvailable, setIsTokenAvailable] = useState(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined)

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElCategory, setAnchorElCategory] = useState(null);
    const [anchorElStages, setAnchorElStages] = useState(null);

    const [sectorMaxHeight, setSectorMaxHeight] = useState("3rem");

    const [isOverflowCategory, setIsOverflowCategory] = useState(false);
    const [isOverflowSectors, setIsOverflowSectors] = useState(false);
    const [isOverflowStages, setIsOverflowStages] = useState(false);
    const categoryRef = useRef(null);
    const sectorRef = useRef(null);
    const stagesRef = useRef(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickCategory = (event) => {
        setAnchorElCategory(event.currentTarget);
    };
    const handleClickStages = (event) => {
        setAnchorElStages(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseCategory = () => {
        setAnchorElCategory(null);
    };
    const handleCloseStages = () => {
        setAnchorElStages(null);
    };

    const open = Boolean(anchorEl);
    const openCategory = Boolean(anchorElCategory);
    const openStages = Boolean(anchorElStages);
    const id = open ? 'simple-popover' : undefined;
    const categoryId = open ? 'simple-popover-category' : undefined;
    const stagesId = open ? 'simple-popover-stages' : undefined;

    const [mode, setMode] = useState(null)

    // const slug = Cookies.get("slug")?.trim()

    let pathname = window.location?.pathname
    let splittedPathname = pathname.split("/")
    let slug = splittedPathname[2]

    const [searchParams] = useSearchParams();
    // const { data: startupDetailsData, isRefetching: isStartupDetailsRefetching, isLoading: isStartupDetailsLoading, refetch: refetchStartupDetails } = FetchStartupDetails(slug)
    const { data: profileDetailsData, isRefetching: isProfileDetailsRefetching, isLoading: isProfileDetailsLoading, refetch: refetchProfileDetails } = FetchProfileDetailsofStartup(isTokenAvailable)

    const {
        mutateAsync: SavePersonalInfoAsync,
        status: savePersonalInfoStatus,
        error: savePersonalInfoError,
        isLoading: isUpdatingsavePersonalInfo,
        data: savePersonalInfoResponse,
    } = SavePersonalInfo();

    // const {
    //     mutateAsync: SaveToListAsync,
    //     status: saveToListStatus,
    //     error: saveToListError,
    //     isLoading: isUpdatingSaveToList,
    //     data: saveToListResponse,
    // } = SaveToList();

    // const {
    //     mutateAsync: SaveInterestAsync,
    //     status: saveInterestStatus,
    //     error: saveInterestError,
    //     isLoading: isUpdatingSaveInterest,
    //     data: saveInterestResponse,
    // } = SaveInterest();

    // const {
    //     mutateAsync: SaveFeedbackAsync,
    //     status: saveFeedbackStatus,
    //     error: saveFeedbackError,
    //     isLoading: isUpdatingSaveFeedback,
    //     data: saveFeedbackResponse,
    // } = SaveFeedback();

    // const {
    //     mutateAsync: SaveUserTypeAsync,
    //     status: saveUserTypeStatus,
    //     error: saveUserTypeError,
    //     isLoading: isUpdatingSaveUserType,
    //     data: saveUserTypeResponse,
    // } = SaveUserType();

    // const {
    //     mutateAsync: FetchStatusAsync,
    //     status: fetchStatusStatus,
    //     error: fetchStatusError,
    //     isLoading: isUpdatingFetchStatus,
    //     data: fetchStatusResponse,
    // } = FetchStatus();

    // const handleNamePopupOpen = () => {
    //     setOpenNamePopup(true);
    // }

    // const handleNamePopupClose = () => {
    //     setOpenNamePopup(false);
    // }

    // const handleNameInvestor = (values) => {
    //     // handleNamePopupClose()
    //     let first_name = values?.first_name
    //     let last_name = values?.last_name
    //     savePersonalInfo(first_name, last_name)
    // }

    // const savePersonalInfo = (first_name, last_name) => {
    //     let request = {
    //         first_name: first_name?.trim(),
    //         last_name: last_name?.trim()
    //     }
    //     SavePersonalInfoAsync(request).catch(err => {

    //         toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     });
    // }

    // const handleInterestPopupOpen = () => {
    //     if (enableInterest) {
    //         setButtonClickType("Interest")
    //         if (userDetail?.first_name?.trim()?.length == 0 || userDetail?.last_name?.trim()?.length == 0) {
    //             setOpenNamePopup(true);
    //         } else {
    //             setOpenInterestPopup(true);
    //         }
    //     } else {
    //         setAlertMessage("You have already shown investment interest on this. Please wait to hear back from the startup.")
    //         setAlertHeading("Investment Interest")
    //         setShowAlert(true)
    //     }
    // }

    // const handleInterestPopupClose = () => {
    //     setOpenInterestPopup(false);
    // }

    // const handleFeedbackPopupOpen = () => {
    //     if (enableFeedback) {
    //         setButtonClickType("Feedback")
    //         if (userDetail?.first_name?.trim()?.length == 0 || userDetail?.last_name?.trim()?.length == 0) {
    //             setOpenNamePopup(true);
    //         } else {
    //             setOpenFeedbackPopup(true);
    //         }
    //     } else {
    //         setAlertMessage("You have already submitted feedback on this. Please wait to hear back from the startup.")
    //         setAlertHeading("Feedback")
    //         setShowAlert(true)
    //     }
    // }
    // const handleFeedbackPopupClose = () => {
    //     setOpenFeedbackPopup(false);
    // }

    // const handleInterestSave = (values) => {
    //     let intrested_amount = values?.intrested_amount
    //     saveInterest(intrested_amount)
    // }
    // const handleFeedbackSave = (values) => {
    //     saveFeedback(values)
    // }

    // const handleTypePopupOpen = () => {
    //     setOpenTypePopup(true);
    // }

    // const handleTypePopupClose = () => {
    //     setOpenTypePopup(false);
    // }

    // const handleUserType = (values) => {
    //     // handleTypePopupClose()
    //     let first_name = values?.first_name
    //     let last_name = values?.last_name
    //     let type = values.type
    //     saveUserType(first_name, last_name, type)
    // }

    useEffect(() => {
        const checkOverflow = () => {
            if (categoryRef.current) {
                // Check if scrollHeight is greater than clientHeight
                const hasOverflow = categoryRef.current.scrollHeight > categoryRef.current.clientHeight;
                setIsOverflowCategory(hasOverflow);
            }
            if (sectorRef.current) {
                // Check if scrollHeight is greater than clientHeight
                const hasOverflow = sectorRef.current.scrollHeight > sectorRef.current.clientHeight;
                setIsOverflowSectors(hasOverflow);
            }
            if (stagesRef.current) {
                // Check if scrollHeight is greater than clientHeight
                const hasOverflow = stagesRef.current.scrollHeight > stagesRef.current.clientHeight;
                setIsOverflowStages(hasOverflow);
            }
        };

        // Run the overflow check
        checkOverflow();

        // document.querySelector('html').firstChild.childNodes.item(1).textContent = 'Kalaari Capital | Backrr'
        // document.querySelector('html').firstChild.childNodes[11].content = 'Kalaari Capital is an early-stage, technology-focused venture capital firm. We invest across Seed and Series A startups and empower visionary entrepreneurs to build unique solutions that reshape the way Indians live, work, consume and transact.'
        // document.querySelector('html').firstChild.childNodes[15].content = 'Kalaari Capital | Backrr'
        // document.querySelector('html').firstChild.childNodes[17].content = 'Kalaari Capital is an early-stage, technology-focused venture capital firm. We invest across Seed and Series A startups and empower visionary entrepreneurs to build unique solutions that reshape the way Indians live, work, consume and transact.'
        // console.log("document", document.querySelector('html').firstChild.childNodes[11].content)

        // Optional: Re-check on window resize
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //     setMode(searchParams.get("q"));
    // }, [window])

    // useEffect(() => {
    //     if (startupDetailsData != null) {
    //         setStartupDetail(startupDetailsData?.data)
    //         setOverview(startupDetailsData?.data?.description?.substring(0, 300))
    //         handleCoFounder(startupDetailsData?.data, startupDetailsData?.data?.cofounder, startupDetailsData?.data?.founder)
    //     } else {
    //         setStartupDetail(null)
    //         setOverview(null)
    //         setCoFoundersArray([])
    //     }
    // }, [startupDetailsData])

    // useEffect(() => {
    //     if (showMore) {
    //         setOverview(startupDetailsData?.data?.description?.substring(0, 300))
    //     } else {
    //         setOverview(startupDetailsData?.data?.description)
    //     }
    // }, [showMore])

    useEffect(() => {
        setUserDetail(profileDetailsData?.data)
        console.log("profileDetailsData", profileDetailsData)
        if (profileDetailsData?.data?.pitch_deck_path == null || profileDetailsData?.data?.pitch_deck_path?.trim()?.length == 0) {
            setEnableShare(false);
        } else {
            setEnableShare(true)
        }
    }, [profileDetailsData])

    useEffect(() => {
        if (userDetail != null && buttonClickType != null) {
            switch (buttonClickType) {
                case 'Share': {
                    handleSharePitchDeck()
                    break;
                }
                // case 'Interest': {
                //     // saveToList(first_name, last_name)
                //     handleInterestPopupOpen()
                //     break
                // }
                // case 'Pitchdeck': {
                //     handleNavigateToPitchDeck();
                //     break;
                // }
                // case 'Feedback': {
                //     // saveToList(first_name, last_name)
                //     handleFeedbackPopupOpen()
                //     break
                // }
                // case 'Round': {
                //     // saveToList(first_name, last_name)
                //     if (isTokenAvailable) {
                //         if (userDetail?.first_name?.trim()?.length == 0 || userDetail?.last_name?.trim()?.length == 0) {
                //             setOpenNamePopup(true);
                //         } else {
                //             window.history.replaceState("", "", `/startup/${slug}?q=funding-round`); setMode("funding-round")
                //         }
                //     }
                //     break
                // }
            }
        }
    }, [userDetail, isTokenAvailable])

    // useEffect(() => {
    //     if (startupDetail != null && userDetail != null) {
    //         fetchStatusOfButtons()
    //     }
    // }, [startupDetail, userDetail])

    // const fetchStatusOfButtons = () => {
    //     let request = {
    //         startup_id: startupDetail?._id,
    //         investor_id: userDetail?.investor_id
    //     }
    //     FetchStatusAsync(request).catch(err => {

    //         toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     });
    // }

    // const handleCoFounder = (startupData, coFounders, founders) => {
    //     let mergedFounders = []
    //     founders?.map(item => {
    //         if (!isEmptyObject(item)) {
    //             mergedFounders.push({
    //                 name: item.name,
    //                 bio: item.bio,
    //                 image: item.founder_image_path,
    //                 linkedin_url: item.linkedin_url,
    //                 type: "Founder"
    //             })
    //         }
    //     })
    //     coFounders?.map(item => {
    //         if (!isEmptyObject(item)) {
    //             mergedFounders.push({
    //                 name: item.name,
    //                 bio: item.bio,
    //                 image: item.cofounder_image_path,
    //                 linkedin_url: item.linkedin_url,
    //                 type: "Co-Founder"
    //             })
    //         }
    //     })
    //     setCoFoundersArray(mergedFounders)
    // }

    const handleLogOut = () => {
        dispatch({ type: SET_MENU, opened: false });
        Cookies.remove('jwt', { path: '/' });
        Cookies.remove('is_profile_complete', { path: '/' });
        Cookies.remove('email', { path: '/' });
        setIsTokenAvailable(false)
        // navigate('/investor/login');
    };

    // const addToMyList = () => {
    //     setButtonClickType("Wishlist")
    //     if (userDetail?.first_name?.trim()?.length > 0 && userDetail?.last_name?.trim()?.length > 0) {
    //         saveToList(userDetail?.first_name, userDetail?.last_name)
    //     } else {
    //         // ask for name
    //         handleNamePopupOpen()
    //     }
    // }

    // const saveToList = (first_name, last_name) => {
    //     let request = {
    //         startup_id: startupDetail?._id,
    //         first_name: first_name?.trim(),
    //         last_name: last_name?.trim()
    //     }
    //     SaveToListAsync(request).catch(err => {

    //         toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     });
    // }

    // const saveInterest = (intrested_amount) => {
    //     let request = {
    //         startup_id: startupDetail?._id,
    //         intrested_amount: intrested_amount
    //     }
    //     SaveInterestAsync(request).catch(err => {

    //         toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     });
    // }

    // const saveFeedback = (values) => {
    //     let request = {
    //         startup_id: startupDetail?._id,
    //         investor_id: userDetail?.investor_id,
    //         ratings: {
    //             problem: values?.problem,
    //             market: values?.market,
    //             product: values?.product,
    //             team: values?.team,
    //             traction: values?.traction
    //         },
    //         investible: values?.invest,
    //         feedback: values?.feedback
    //     }
    //     SaveFeedbackAsync(request).catch(err => {

    //         toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     });
    // }

    // const saveUserType = (first_name, last_name, type) => {
    //     let request = {
    //         first_name: first_name?.trim(),
    //         last_name: last_name?.trim(),
    //         type: type
    //     }
    //     SaveUserTypeAsync(request).catch(err => {

    //         toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     });
    // }

    // useEffect(() => {
    //     if (savePersonalInfoResponse && savePersonalInfoResponse?.data?.statusCode == 200) {
    //         handleNamePopupClose()
    //         refetchProfileDetails()
    //     } else {
    //         savePersonalInfoResponse?.data?.message && toast.error(<CustomToast message={savePersonalInfoResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     }
    // }, [savePersonalInfoResponse?.data?.statusCode]);

    // useEffect(() => {
    //     if (saveToListResponse && saveToListResponse?.data?.statusCode == 200) {
    //         toast.success(<CustomToast message={saveToListResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     } else {
    //         saveToListResponse?.data?.message && toast.error(<CustomToast message={saveToListResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     }
    //     handleNamePopupClose()
    //     setButtonClickType(null)
    //     // refetchStartupDetails()
    //     fetchStatusOfButtons()
    // }, [saveToListResponse?.data?.statusCode]);

    // useEffect(() => {
    //     if (saveInterestResponse && saveInterestResponse?.data?.statusCode == 200) {
    //         toast.success(<CustomToast message={saveInterestResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     } else {
    //         saveInterestResponse?.data?.message && toast.error(<CustomToast message={saveInterestResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     }
    //     handleInterestPopupClose()
    //     setButtonClickType(null)
    //     // refetchStartupDetails()
    //     fetchStatusOfButtons()
    // }, [saveInterestResponse?.data?.statusCode]);

    // useEffect(() => {
    //     if (saveFeedbackResponse && saveFeedbackResponse?.data?.statusCode == 200) {
    //         toast.success(<CustomToast message={saveFeedbackResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     } else {
    //         saveFeedbackResponse?.data?.message && toast.error(<CustomToast message={saveFeedbackResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     }
    //     handleFeedbackPopupClose()
    //     setButtonClickType(null)
    //     // refetchStartupDetails()
    //     fetchStatusOfButtons()
    // }, [saveFeedbackResponse?.data?.statusCode]);

    // useEffect(() => {
    //     if (saveUserTypeResponse && saveUserTypeResponse?.data?.statusCode == 200) {
    //         toast.success(<CustomToast message={saveUserTypeResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     } else {
    //         saveUserTypeResponse?.data?.message && toast.error(<CustomToast message={saveUserTypeResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //             duration: 4000,
    //             position: "top-right",
    //             style: {
    //                 padding: "15px 30px",
    //             },
    //         });
    //     }
    //     handleTypePopupClose()
    //     redirectToDashdoard()
    //     // refetchStartupDetails()
    //     fetchStatusOfButtons()
    // }, [saveUserTypeResponse?.data?.statusCode]);

    // useEffect(() => {
    //     if (fetchStatusResponse && fetchStatusResponse?.data?.statusCode == 200) {
    //         setEnableInterest(fetchStatusResponse?.data?.investorInfo == null)
    //         setEnableFeedback(fetchStatusResponse?.data?.feedback == null)
    //     } else {
    //         // fetchStatusResponse?.data?.message && toast.error(<CustomToast message={fetchStatusResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
    //         //     duration: 4000,
    //         //     position: "top-right",
    //         //     style: {
    //         //         padding: "15px 30px",
    //         //     },
    //         // });
    //     }
    // }, [fetchStatusResponse?.data?.statusCode]);

    // const redirectToDashdoard = () => {

    // }

    // const getCategoryIcon = () => {
    //     switch (startupDetail?.category_info) {
    //         case "Technology": {
    //             // return <MemoryOutlined sx={{ fontSize: { xs: '4.3rem', md: '4.2rem' }, paddingTop: "1rem", marginLeft: 'auto', transform: `translate(8px, 8px)` }} />
    //             return <img src={laptopScreenIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto' }} alt='icon' />
    //         }
    //         case "Consumer Brand": {
    //             // return <StorefrontOutlined sx={{ fontSize: { xs: '4.3rem', md: '4.2rem' }, paddingTop: "1rem", marginLeft: 'auto', transform: `translate(8px, 8px)` }} />
    //             return <img src={mobileShoppingIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto' }} alt='icon' />

    //         }
    //         case "Marketplace": {
    //             // return <LocalMallOutlined sx={{ fontSize: { xs: '4.3rem', md: '4.2rem' }, paddingTop: "1rem", marginLeft: 'auto', transform: `translate(8px, 8px)` }} />
    //             return <img src={marketplaceIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto', paddingRight: "0.6rem" }} alt='icon' />
    //         }

    //         case "Services": {
    //             // return <MiscellaneousServicesOutlined sx={{ fontSize: { xs: '4.3rem', md: '4.2rem' }, paddingTop: "1rem", marginLeft: 'auto', transform: `translate(8px, 8px)` }} />
    //             return <img src={digitalServiceIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto', transform: 'rotateY(180deg)' }} alt='icon' />
    //         }
    //     }
    // }

    const getCategoryColor = (category) => {
        switch (category) {
            case "Technology": { return technologyColor }
            case "Consumer Brand": { return consumerBrandColor }
            case "Marketplace": { return marketplaceColor }
            case "Services": { return servicesColor }
            default: { return sectorAgnostic }
        }
    }

    // const getStageIcon = () => {
    //     switch (startupDetail?.stage_info?.config_values?.name) {
    //         case "Pre-Seed": {
    //             return <img src={preSeedImage} rel='preload' style={{ width: "5rem", height: "1.649rem", paddingTop: "3.5rem", marginLeft: 'auto' }} />
    //         }
    //         case "Seed": {
    //             return <img src={seedImage} rel='preload' style={{ width: "5rem", height: "2.649rem", paddingTop: "2.5rem", marginLeft: 'auto' }} />
    //         }
    //         case "Early Stage": {
    //             return <img src={earlyStageImage} rel='preload' style={{ width: "5rem", height: "3.649rem", paddingTop: "1.5rem", marginLeft: 'auto' }} />
    //         }
    //         case "Growth": {
    //             return <img src={growthImage} rel='preload' style={{ width: "5rem", height: "5.2rem", paddingTop: "0rem", marginLeft: 'auto' }} />
    //         }
    //         case "Expansion": {
    //             return <img src={ipoImage} rel='preload' style={{ width: "5rem", paddingTop: "1rem", marginLeft: 'auto' }} />
    //         }
    //     }
    // }

    // const isOverflown = (element) => {
    //     console.log("overflow", element?.parentNode?.scrollHeight > element?.parentNode?.clientHeight || element?.parentNode?.scrollWidth > element?.parentNode?.clientWidth)
    //     return element?.parentNode?.scrollHeight > element?.parentNode?.clientHeight || element?.parentNode?.scrollWidth > element?.parentNode?.clientWidth;
    // }

    const getRandomColor = () => {
        var copy = colorArray.slice(0);
        return function () {
            if (copy.length < 1) { copy = colorArray.slice(0); }
            var index = Math.floor(Math.random() * copy.length);
            var item = copy[index];
            copy.splice(index, 1);
            return item;
        };
    }

    const handleSharePitchDeck = () => {
        if (isTokenAvailable) {
            if (enableShare) {
                sharePitchDeck()
            } else {
                completeOnboarding()
            }
        } else {
            setButtonClickType("Share")
            setOpenLoginPopup(true)
        }
    }

    const sharePitchDeck = () => {
        alert("share Pitch Deck " + userDetail?.pitch_deck_path)
    }

    const completeOnboarding = () => {
        alert("complete onboarding")
        // window.open(`http://localhost:3000/onboarding?token=${Cookies.get('jwt')}&is_profile_completed=false`, "_blank")
    }

    // const ButtonsRow = () => {
    //     return <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
    //         {/* <B300 onClick={addToMyList} startIcon={<Add fontSize='1.25rem' />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize:'0.7rem' }} style={{ lineHeight:'13px'}}>
    //             Add to my list
    //         </B300> */}
    //         <B300 onClick={handleInterestPopupOpen} startIcon={<ThumbUp sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
    //             Show Interest
    //         </B300>
    //         <B300 onClick={handleFeedbackPopupOpen} startIcon={<RateReview sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
    //             Submit Feedback
    //         </B300>
    //         <B300 onClick={() => { window.history.replaceState("", "", `/startup/${slug}?q=funding-round`); setMode("funding-round") }} startIcon={<RoundDetailsIcon fill={"#FFF"} height={20} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
    //             Round Details
    //         </B300>
    //     </Grid >
    // }
    const commonCardStyles = {
        padding: '1rem',
        // paddingBottom: '1rem',
        boxShadow: 'none',
        background: 'transparent',
        // backgroundColor: "#FFFAF5",
        overflow: 'hidden',
        // border: `1px solid ${theme.palette.customColors.indigo600}`,
        border: `1px solid #FFF`,
        borderRadius: '15px',
    };

    const commonInvestmentFocusStyles = {
        borderRadius: '8px',
        backgroundColor: 'white',
        padding: '1rem',
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex'
    };

    const commonInvestmentFocusIconStyles = {
        height: '2.5rem',
        width: '2.5rem',
        objectFit: 'contain',
    };

    return (

        <AuthWrapperInvestor sx={{ height: '100%' }} theme={theme}>
            <MainWrappperInvestor profileData={userDetail} startupData={startupDetail} theme={theme} className={'investor'} footerColor={'#FFF'}>
                {/* <Helmet>
                    <title>{startupDetail != null ? startupDetail?.startup_name + " | Backrr" : "Backrr"}</title>
                    <meta name="description" content={startupDetail?.problem_solving} />
                    <meta property="og:title" content={startupDetail != null ? startupDetail?.startup_name + " | Backrr" : "Backrr"} />
                    <meta property="og:description" content={startupDetail?.problem_solving} />
                </Helmet> */}

                <Helmet>
                    <title>{`${investorData?.name} | Backrr`}</title>
                    <meta name="description" content={`${investorData?.bio}`} />
                    <meta property="og:title" content={`${investorData?.name} | Backrr`} />
                    <meta property="og:description" content={`${investorData?.bio}`} />
                </Helmet>

                <main className='investor'>
                    {(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined) && <Grid position={'fixed'} right={10} top={10} zIndex={100}>
                        <IconButton onClick={handleLogOut}><Logout sx={{ color: "#FFF" }} /></IconButton>
                    </Grid>}
                    {
                        // isStartupDetailsLoading ?
                        //     <Grid xs={12} container display={"block"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                        //         maxWidth: '900px !important',
                        //         marginInline: 'auto',
                        //         height: '100%',
                        //         paddingTop: '0.5rem !important'
                        //     }}>
                        //         <Grid container xs={12} alignItems={"center"} spacing={2} pb={2} marginInline={"auto"} position={"sticky"} top={0} bgcolor={"#FFFAF5"} sx={{ zIndex: 1 }}>
                        //             <Grid item xs={12} pl={"0 !important"}>
                        //                 <div className='skeleton' style={{ width: "6rem", height: "6rem", borderRadius: '3rem', marginInline: 'auto', display: "block" }} />
                        //             </Grid>
                        //             <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1} marginTop={1}>
                        //                 <div className='skeleton' style={{ marginInline: 'auto', width: '6rem', height: '2rem' }} />
                        //             </Grid>
                        //             <div className='skeleton' style={{ marginInline: 'auto', height: '0.8rem', width: '6rem', marginTop: 2 }} />

                        //             <Grid spacing={2} marginTop={1} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                        //                 <div className='skeleton' style={{ display: 'inline-block', width: "3rem", height: "3rem" }} />
                        //                 <div className='skeleton' style={{ display: 'inline-block', width: "3rem", height: "3rem" }} />
                        //             </Grid>
                        //         </Grid>
                        //         <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} bgcolor={"#FFFAF5"}>
                        //             <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} padding={{ xs: "0rem 1rem 0rem 1rem", sm: "0rem 1rem 0rem 1rem" }}>
                        //                 <div className='skeleton' style={{ marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '0.1rem', width: '10rem', height: '2rem' }} />
                        //             </Grid>
                        //             <Grid item sx={{
                        //                 width: '100%', minHeight: "40rem", height: '100%',
                        //                 borderRadius: '8px', padding: { xs: "1rem !important", md: "1rem 2rem !important" },
                        //                 gap: "1rem"
                        //             }}>

                        //                 <ButtonsRow />
                        //                 {/* <Card sx={{ padding: '1rem', boxShadow: 'none', background: "transparent", overflow: 'hidden', borderRadius: '15px', marginTop: '25px' }}>
                        //                     <div className='skeleton' style={{ height: '10rem', width: '100%', borderRadius: '15px', }} />
                        //                 </Card> */}

                        //             </Grid>

                        //         </Grid>
                        //     </Grid> :
                        <Grid container xs={12} gap={"1.25rem"} display={"grid"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }}
                            sx={{
                                maxWidth: '900px !important',
                                marginInline: 'auto',
                                height: '100%',
                                paddingTop: '1.5rem !important'
                            }}
                            gridTemplateAreas={`"first" "second" "third" "fourth" "fifth"`}
                            gridTemplateColumns={'1fr'} justifyContent={"space-between"}>
                            <Grid sx={{ gridArea: 'first', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item >
                                <Card sx={{ padding: '1rem', boxShadow: 'none', overflow: 'hidden', backgroundColor: 'transparent' }}>
                                    {/* <CardHeader title={"Personal Info"} /> */}
                                    <Grid container xs={12} alignItems={"center"} spacing={2} marginInline={"auto"}>
                                        <Grid sx={{ borderRadius: '5rem', width: '8rem', height: '8rem', marginInline: 'auto', backgroundColor: "#FFF", alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>
                                            <img src={investorData?.profile_image} onLoad={() => { setShowInitialLogo(false) }} onError={() => { setShowInitialLogo(true) }} style={{ width: '6rem', height: '6rem', borderRadius: '2rem', marginInline: 'auto', display: "block", objectFit: 'contain' }} />
                                        </Grid>
                                        <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                            <Typography variant='titleInvestor' sx={{ fontSize: "1.3rem", textAlign: 'center', marginInline: 'auto' }}>{investorData?.name}</Typography>
                                        </Grid>
                                        {/* <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} padding={'0rem 1rem 0.5rem 1rem'}>
                                            <Typography fontSize={"0.9rem"} variant='bodyTextRegularInvestor' sx={{ textAlign: 'center', marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '0.1rem' }}>We are the innovation continuum</Typography>
                                        </Grid> */}
                                        <Typography variant='bodyTextRegularInvestor' sx={{ fontSize: { xs: "0.8rem", md: "0.8rem" }, textAlign: 'center', marginInline: 'auto' }}>{investorData?.location}
                                            <span>
                                                <img src={indiaFlag} rel='preload' style={{ width: "1.2rem", marginLeft: '5px' }} />
                                            </span>
                                        </Typography>

                                        <Grid spacing={2} marginTop={1} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                            <button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><LinkedInIcon sx={{ fontSize: 30, borderRadius: 10, color: "#FFF" }} style={{ display: 'inline-block' }} onClick={() => { window.open(investorData?.linkedin_url) }} /></button>
                                            {/* <button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><LanguageIcon sx={{ fontSize: 30 }} style={{ display: 'inline-block' }} onClick={() => { window.open("https://backrr.in/investor/pranay_mathur") }} /></button> */}
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card sx={{ ...commonCardStyles }}>
                                    <CardHeader title={"Overview"} />
                                    <RichTextDisplay htmlContent={investorData?.overview} color={"white"} textAlign='justify' />
                                </Card>
                            </Grid>
                            <Grid sx={{ gridArea: 'second', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                {/* <CardHeader title={"Investment Focus"} sx={{ color: "#FFF" }} /> */}
                                <Card sx={{ ...commonCardStyles }}>
                                    <CardHeader title={"Investment Focus"} />
                                    <Grid
                                        container
                                        display={'flex'}
                                        xs={12}
                                        sx={{
                                            overflow: "hidden",
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                                <Grid flex={0.7}>
                                                    <img src={categoryIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                                    <Grid mt={'5px'}>
                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem', color: theme.palette.customColors.blue }}>Categories</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid flex={1} alignItems={'start'}>
                                                    <Grid ref={categoryRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4, color: theme.palette.customColors.blue }}>{investorData?.categories?.join(", ")}</Typography>
                                                    </Grid>
                                                    <span>
                                                        {
                                                            isOverflowCategory && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, color: theme.palette.customColors.blue, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                                setAlertImage(categoryIcon)
                                                                setAlertHeading('Categories')
                                                                setAlertMessage(investorData?.categories?.join(", "))
                                                                setShowAlert(true)
                                                            }}>...more</Typography>
                                                        }
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus'>
                                                <Grid flex={0.7}>
                                                    <img src={stageIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                                    <Grid mt={'5px'}>
                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem', color: theme.palette.customColors.blue }}>Stages</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid flex={1} alignItems={'start'}>
                                                    <Grid ref={stagesRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4, color: theme.palette.customColors.blue }}>{investorData?.stages?.join(", ")}</Typography>
                                                    </Grid>
                                                    <span>
                                                        {
                                                            isOverflowStages && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, color: theme.palette.customColors.blue, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                                setAlertImage(stageIcon)
                                                                setAlertHeading('Stages')
                                                                setAlertMessage(investorData?.stages?.join(", "))
                                                                setShowAlert(true)
                                                            }}>...more</Typography>
                                                        }
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                                <Grid flex={0.7}>
                                                    <img src={sectorIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                                    <Grid mt={'5px'}>
                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem', color: theme.palette.customColors.blue }}>Sectors</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid flex={1} alignItems={'start'}>
                                                    <Grid ref={sectorRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4, color: theme.palette.customColors.blue }}>{investorData?.sectors?.join(", ")}</Typography>
                                                    </Grid>

                                                    <span>
                                                        {
                                                            isOverflowSectors && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, color: theme.palette.customColors.blue, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                                setAlertImage(sectorIcon)
                                                                setAlertHeading('Sectors')
                                                                setAlertMessage(investorData?.sectors?.join(", "))
                                                                setShowAlert(true)
                                                            }}>...more</Typography>
                                                        }
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                                <Grid flex={0.7}>
                                                    <img src={chequeSizeIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                                    <Grid mt={'5px'}>
                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem', color: theme.palette.customColors.blue }}>Checque Size</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid display={'flex'} flex={1} maxHeight={'4rem'} overflow={'hidden'} alignItems={'start'}>
                                                    <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4, color: theme.palette.customColors.blue }}>{investorData?.checque_size}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid
                                        container
                                        xs={12}
                                        gap={1}
                                        sx={{
                                            overflow: "hidden",
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {
                                            categoryArray?.map((item, index) => {
                                                return (
                                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor(item.title) }}>
                                                        <img src={item.icon} onLoad={() => { setShowInitialLogo(false) }} onError={() => { setShowInitialLogo(true) }} style={{ width: "0.9rem", height: "0.9rem", objectFit: 'contain' }} />
                                                        <Grid>
                                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, marginLeft: "0.3rem" }}>{item.title}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid> */}
                                </Card>
                            </Grid>
                            {/* <Grid sx={{ gridArea: 'third', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                <Card sx={{ ...commonCardStyles }}>
                                    <CardHeader title={"Sectors"} />
                                    <Grid
                                        ref={sectorRef}
                                        container
                                        xs={12}
                                        gap={1}
                                        sx={{
                                            overflow: "hidden",
                                            justifyContent: 'start',
                                            alignItems: 'center',
                                            maxHeight: sectorMaxHeight
                                        }}
                                    >
                                        {
                                            sectorArray?.map((item, index) => {
                                                return (
                                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor(item.category) }}>
                                                        <Grid>
                                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>{item.title}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    {
                                        isOverflowSectors && sectorMaxHeight != "auto" && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, color: 'white', justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => { setSectorMaxHeight("auto") }}>See All</Typography>
                                    }
                                </Card>
                            </Grid>
                            <Grid sx={{ gridArea: 'fifth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                <Card sx={{ ...commonCardStyles }}>
                                    <CardHeader title={"Stages"} />
                                    <Grid
                                        container
                                        display={'grid'}
                                        gridTemplateColumns={'repeat(auto-fill, minmax(14rem, 1fr))'}
                                        xs={12}
                                        gap={2}
                                        sx={{
                                            overflow: "hidden",
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {
                                            stagesArray?.map(item => {
                                                return (
                                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', justifyContent: 'start', alignItems: 'normal', backgroundColor: 'white' }}>
                                                        <Grid display={'flex'} sx={{ padding: "12px 10px", backgroundColor: item.disable ? theme.palette.customColors.disable : theme.palette.customColors.indigo600, borderRight: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '7px', borderTopRightRadius: 0, borderBottomRightRadius: 0, alignItems: 'center' }}>
                                                            <CheckIcon style={{ height: '1rem', width: '1.3rem', color: item.disable ? "grey" : "white" }} />
                                                        </Grid>
                                                        <Grid sx={{ padding: "12px" }}>
                                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>{item.title}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid sx={{ gridArea: 'sixth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                <Card sx={{ ...commonCardStyles }}>
                                    <CardHeader title={"Investment size"} />
                                    <Grid
                                        container
                                        xs={12}
                                        sx={{
                                            overflow: "hidden",
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, padding: "0.4rem", borderRadius: "8px", width: '40%', backgroundColor: 'white' }} alignItems={'center'} justifyContent={'center'}>
                                            <Typography variant='title' sx={{ fontSize: "large", textAlign: 'center', marginInline: 'auto', paddingX: '0.2rem' }}>
                                                ₹50L
                                            </Typography>
                                        </Grid>
                                        <Typography variant='title' sx={{ fontSize: "medium", textAlign: 'center', paddingX: '0.2rem', width: '10%', color: 'white' }}>
                                            TO
                                        </Typography>
                                        <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, padding: "0.4rem", borderRadius: "8px", width: '40%', backgroundColor: 'white' }} alignItems={'center'} justifyContent={'center'}>
                                            <Typography variant='title' sx={{ fontSize: "large", textAlign: 'center', marginInline: 'auto', paddingX: '0.2rem' }}>
                                                ₹2CR
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid> */}
                            <Grid sx={{ gridArea: 'fourth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                <Card sx={{ ...commonCardStyles }}>
                                    <CardHeader title={"Portfolio"} />
                                    <Grid
                                        container
                                        display={'flex'}
                                        xs={12}
                                        sx={{
                                            overflow: "hidden",
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {
                                            investorData?.portfolio?.map(item => {
                                                return (
                                                    <Grid item width={{ xs: "50%", sm: "33.33%", md: "25%", lg: "20%", xl: "16.66%" }} padding={"0.5rem"}>
                                                        <Grid sx={{ border: `1px solid ${theme.palette.customColors.g75}`, padding: "0.4rem", cursor: 'pointer', borderRadius: "8px", backgroundColor: 'white' }} alignItems={'center'} justifyContent={'center'} onClick={() => { window.open(item.startup_url) }}>
                                                            <img src={item.startup_logo} onLoad={() => { setShowInitialLogo(false) }} onError={() => { setShowInitialLogo(true) }} style={{ width: "4rem", height: "4rem", borderRadius: '8px', marginInline: 'auto', display: "block", objectFit: 'contain' }} />
                                                        </Grid>
                                                        <Grid display={'flex'} alignItems={"center"} justifyContent={"center"}>
                                                            <Typography variant='titleNormal' sx={{ fontSize: "1rem", textAlign: 'center', marginInline: 'auto', cursor: 'pointer', color: 'white' }} onClick={() => { window.open(item.startup_url) }}>{item.startup_name}
                                                                {/* <span>
                                                                    <OpenInNewIcon sx={{ fontSize: 15, color: theme.palette.customColors.indigo600, cursor: 'pointer', marginLeft: '2px' }} onClick={() => { window.open(item.startup_url) }} />
                                                                </span> */}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid sx={{ gridArea: 'third', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                <Card sx={{ ...commonCardStyles }}>
                                    <CardHeader title={"Team"} />
                                    <Grid
                                        container
                                        display={'flex'}
                                        gap={2}
                                        xs={12}
                                        sx={{
                                            overflow: "hidden",
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {
                                            investorData?.team?.map((person, index) => {
                                                return <PersonDetails theme={theme} data={person} key={index} index={index} length={investorData?.team?.length} />
                                                // return (
                                                //     <Grid item width={{ xs: "100%", sm: "33.33%", md: "25%", xl: "25%" }} padding={"0.5rem"}>
                                                //         <Grid position={'relative'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, cursor: 'pointer', borderRadius: "8px", backgroundColor: 'white' }} alignItems={'center'} justifyContent={'center'} onClick={() => { window.open(item.linkedin_url) }}>
                                                //             <img src={item.partner_image} onLoad={() => { setShowInitialLogo(false) }} onError={() => { setShowInitialLogo(true) }} style={{ width: "10rem", height: "10rem", marginInline: 'auto', display: "block", objectFit: 'contain' }} />
                                                //             <div style={{ position: 'absolute', zIndex: 1000, right: 10, bottom: 5 }}>
                                                //                 <LinkedInIcon sx={{ fontSize: 15, color: theme.palette.customColors.indigo600, cursor: 'pointer' }} onClick={() => { window.open(item.linkedin_url) }} />
                                                //             </div>
                                                //         </Grid>
                                                //         <Grid display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                //             <Typography variant='titleNormal' sx={{ fontSize: "1rem", textAlign: 'center', marginInline: 'auto', cursor: 'pointer', color: 'white' }} onClick={() => { window.open(item.linkedin_url) }}>{item.partner_name}</Typography>
                                                //         </Grid>
                                                //     </Grid>
                                                // )
                                            })
                                        }
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid sx={{ gridArea: 'fifth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                <Card sx={{ ...commonCardStyles }}>
                                    <CardHeader title={"Gallery"} />
                                    <Grid
                                        container
                                        display={'flex'}
                                        xs={12}
                                        sx={{
                                            overflow: "hidden",
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CustomGallery imagesArray={investorData?.gallery} theme={theme} />
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid >

                    }
                    <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
                        <B300 onClick={handleSharePitchDeck} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid #FFF`, padding: '16px', fontWeight: 500, borderRadius: "8px", height: '40px', fontSize: '0.8rem' }} className="share-button" style={{ lineHeight: '15px' }}>
                            {`Share Pitch Deck with ${investorData?.name}`}
                        </B300>
                    </Grid>
                    <Dialog fullScreen={true} open={openLoginPopup} onClose={() => { setOpenLoginPopup(false); setIsTokenAvailable(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined) }} sx={{}}>
                        <DialogActions>
                            <Tooltip title="Close">
                                <IconButton disableRipple={true} sx={{ padding: 0 }}>
                                    <CloseIcon width={40} height={40} style={{ marginRight: 0 }} onClick={() => { setOpenLoginPopup(false); setIsTokenAvailable(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined); setButtonClickType(null) }} />
                                </IconButton>
                            </Tooltip>
                        </DialogActions>
                        <DialogContent sx={{ padding: 0 }}>
                            <LoginPopupforInvestor handleClose={() => {
                                setOpenLoginPopup(false);
                                setIsTokenAvailable(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined);
                                refetchProfileDetails()
                            }} />
                        </DialogContent>
                    </Dialog>
                    <AlertDialog open={showAlert} handleClose={() => { setShowAlert(false); setAlertMessage(null); setAlertHeading(null); setAlertImage(null) }} heading={alertHeading} subheading={alertMessage} image={alertImage} />
                </main>
            </MainWrappperInvestor>
        </AuthWrapperInvestor>
    )
}

export default MicroVC